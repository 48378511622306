import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О компании | Fairway Champions Golf Club Services
			</title>
			<meta name={"description"} content={"Ознакомьтесь с нашими лучшими услугами для гольфа"} />
			<meta property={"og:title"} content={"О компании | Fairway Champions Golf Club Services"} />
			<meta property={"og:description"} content={"Ознакомьтесь с нашими лучшими услугами для гольфа"} />
			<meta property={"og:image"} content={"https://crystalinedream.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crystalinedream.com/img/8598127.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crystalinedream.com/img/8598127.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crystalinedream.com/img/8598127.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crystalinedream.com/img/8598127.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crystalinedream.com/img/8598127.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crystalinedream.com/img/8598127.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 30px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="50%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 62px/1.2 --fontFamily-sans"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Знакомство с нашими услугами
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					В гольф-клубе Fairway Champions каждая услуга, которую мы предлагаем, призвана улучшить ваш опыт игры в гольф и удовлетворить ваши личные потребности. От качества наших полей до разнообразия удобств - мы заботимся о том, чтобы каждый наш визит был незабываемым. Наш клуб не только предоставляет исключительные возможности для игры в гольф, но и уделяет особое внимание всестороннему удовлетворению потребностей гостей.
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Связаться с нами
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="50%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Премьерные поля для гольфа
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Разнообразие планировок: От сложных лунок на склонах холмов до спокойных фервеев на берегу озера - каждое поле предлагает свои особенности и сложности.
						<br/>
Передовое техническое обслуживание: Мы используем передовые технологии и экологически чистые методы, чтобы поддерживать зелень в первозданном виде круглый год.
						<br/>
Фирменные лунки: Оцените нашу знаменитую 18-ю лунку с панорамным видом и стратегическим дизайном, которая является любимой среди членов клуба.

						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Профессиональный магазин и оборудование
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Лучшие бренды в наличии: В наличии самые последние бренды и оборудование для гольфа, начиная от драйверов и заканчивая одеждой.
Индивидуальные настройки: Наш квалифицированный персонал поможет подогнать ваши клюшки для улучшения вашего замаха и общей игры.
Возможность аренды: Высококачественные клюшки и тележки можно взять напрокат, чтобы получить все самое лучшее, не прибегая к помощи своих.

						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Обучение и совершенствование
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Профессиональные тренеры: учитесь у опытных профессионалов гольфа на индивидуальных или групповых занятиях, соответствующих вашему уровню мастерства.
Клиники и семинары: Регулярные семинары, охватывающие темы от основ для начинающих до продвинутых техник.
Видеоанализ: Использование самых современных технологий для анализа и улучшения динамики вашего свинга.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Узнайте больше вместе с нами
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Этот обзор затрагивает лишь поверхность того, что может предложить гольф-клуб Fairway Champions. Погрузитесь глубже в наши услуги мирового класса, посетив нас или обратившись за более подробной информацией. Откройте для себя весь спектр наших предложений и создайте свой идеальный опыт игры в гольф.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://crystalinedream.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://crystalinedream.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});